/*
* = Buttons
*/

.btn {
    &.btn-circle {
        border-radius: $circle-radius;
    }
	&.btn-md{
        padding: 0.65rem 1.25rem;
    }
    &.btn-xs{
        padding: .2rem 0.35rem;
        font-size: 0.55rem;
        box-shadow: none;
    }
    &.large-form-btn{
        padding: 1.2rem 1rem;
    }
    &.dashed-outline {
        border-style: dashed;
    }
}


.btn-icon-only {
    width: 2.575rem;
    height: 2.575rem;
    padding: 0;
    @include display-flex();
    @include justify-content(center);
    @include align-items(center);

    &.btn-xs {
        width: 1.7rem;
        height: 1.7rem;
    }
    &.btn-sm {
        width: 2rem;
        height: 2rem;
    }
}

.btn-upgrade-pro {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 190px;

    @include media-breakpoint-up(md) {
        left: 35px;
    }
}

// Fix for darken background color
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        &:hover{
           background-color:  darken( $value, 5% );
           border-color: darken( $value, 5% );
        }
    }   
}

// Brand (social) buttons 
@each $color, $value in $brand-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);

		&.btn-link {
			color: $value;
			background: transparent;
			box-shadow: none;
            border:0;
            border-style: none;
			&:hover,
			&:focus,
			&.active {
                background-color: transparent !important;
                box-shadow: none;
                border: 0;
				color: $value;
			}
		}
    }
}
